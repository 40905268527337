<template>
    <n-list value="items" text="選項" :fields="fields" :items="items" />
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            fields: [
                {
                    key: 'name',
                    label: '名稱',
                    sortable: true,
                },
            ]
        }
    },

    created() {
        this.$store.dispatch('bindCollectionByMerchant', { col: 'items', merchantId: localStorage.merchantId })
    },

    computed: {
        ...mapState({
            items: state => state.items,
        })
    }
}
</script>